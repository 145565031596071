import { useState, useEffect } from 'react';
import socketClient from 'socket.io-client';

import { useStore } from '../../contexts/message';
import useConfig from './useConfig';

const useMessage = (loaderName) => {
	const { clientConfig } = useConfig();
	const [handleConnection] = useState(loaderName === 'system-messages');

	const [ioServerUrl] = useState(clientConfig('SOCKET_SERVER_URL'));

	const { data: messageData, dispatch: messageDispatch } = useStore();
	const { socket } = messageData;

	const initSocket = () => {
		if (socket === null && handleConnection) {
			const sc = socketClient(ioServerUrl);
			messageDispatch({ type: 'SET_SOCKET', payload: sc });
		}
	};

	useEffect(() => initSocket(), []);

	useEffect(() => {
		if (socket !== null && handleConnection) {
			socket.on('connect', () => {
				// console.log('Socket connected.'); // , socket.id
				messageDispatch({ type: 'SET_SOCKET_ID', payload: socket.id });
			});

			socket.on('disconnect', (reason) => {
				// console.log('Socket disconnected', reason);
				messageDispatch({ type: 'SET_SOCKET_ID', payload: null });
			});

			socket.on('connect_error', (error) => {
				// console.log('Socket connect error', error);
				messageDispatch({ type: 'SET_SOCKET_ID', payload: null });
			});
		}
	}, [socket]);

	return { messageData, messageDispatch };
};

export default useMessage;
